﻿import ko = require("knockout")
import $ = require('jquery')

import { FirmwareVersion } from "./FirmwareVersion"

import { CommonViewModel } from "../Common"
import { ViewModelBase, ViewModelBaseOptions } from "../ViewModelBase"
import { FirmwareDialogModel } from "./FirmwareDialog"

const common: CommonViewModel = globalThis.DIG.Common

export class FirmwareViewModel extends ViewModelBase<FirmwareVersion> {
    options: ViewModelBaseOptions = {
        settingPrefix: "Firmware",
        allowSelection: true,
        allowMultipleSelections: false,
        clearSelectionsOnPageChange: false,
        showAddNew: true,
        detail: {
            enabled: true,
            nextRow: true,
            right: false,
            bottom: false,
            expandAllRows: false,
            detailSize: 0
        },
        loadDataOnInit: true,
        autoRefresh: {
            enabled: false,
            show: false,
            intervalSeconds: 0
        },
        flashNewRows: false,
        showExportExcel: false,
        showExportPDF: false,
        allowMouseScroll: true,
        keyBindings: {
            enable: true,
            enter: false,
            esc: false
        },
        templateSections: {
            queryParameters: false,
        },
        sortFields: ['Build Time', 'Description', 'Device Type', 'Firmware Type', 'Release', 'Version'],
        pageSizeOptions: [10, 20, 50, 100],
        pageActions: [],
        selectionActions: [],
        rightClickActions: []
    }

    firmwareDialog: FirmwareDialogModel = new FirmwareDialogModel({
        isReadOnly: true,
        allowEdit: false
    });

    protected createItem = (data?: object, view?: object): FirmwareVersion => new FirmwareVersion(data, view)

    protected fetchData = async (): Promise<object> => {
        return new Promise((resolve, reject) => {
            $.get('/api/firmware')
                .done(results => {
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("FirmwareViewModel::fetchData()", request, textStatus, error);
                    reject();
                })
        });
    }

    addVersion = (firmwareVersionId: number, version) => {
        this.data.mappedCreate(version);
        this.sort.valueHasMutated();

        common.toast("success", `${version.description} was added.`, 'Add Version');
    }

    removeVersion = (firmwareVersionId) => {
        ko.utils.arrayRemoveItem(this.data(), this.find(firmwareVersionId));
        this.sort.valueHasMutated();
    }

    onAddNew = () => {
        this.firmwareDialog.edit(null);
    }

    public constructor() {
        super();
        this.firmwareDialog.onSave = this.addVersion;
    }
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Firmware ??= () => { /* */ };
globalThis.DIG.Firmware.ViewModel = FirmwareViewModel