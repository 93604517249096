﻿
import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel, EntityType } from "../Common"
import { User } from "./User"
import { Validation } from "../ValidationExtenders"
import { UserPermission } from "./UserPermission"
import { UserPermissionoption } from "./UserPermissionOption"
import { BSTreeView, BS5Theme, FAIconTheme, EVENT_INITIALIZED, BSTreeViewNode, EVENT_NODE_SELECTED, EVENT_NODE_EXPANDED, EVENT_NODE_COLLAPSED } from "@jbtronics/bs-treeview";
import { GenericEntityWithStatus } from "../GenericEntity"
import BSTreeViewNodeState from "@jbtronics/bs-treeview/build/main/lib/BSTreeViewNodeState"
import { SecurityGroup } from "./SecurityGroup"
import { UserGroup } from "./UserGroup"

const common: CommonViewModel = globalThis.DIG.Common

export class UserDialogModel {
    //
    //  Events
    //
    onSave: Function = null;

    //
    //  Properties
    //

    permissions: ko.ObservableArray<UserPermission> = ko.observableArray<UserPermission>([])
    permissionTreeNodes = null;
    tree: BSTreeView = null;
    

    selectedPermissionId: ko.Observable<number> = ko.observable(-1);
    selectedPermissionChoice: ko.Observable<number> = ko.observable(-1);
    selectedPermission: ko.Observable<UserPermission> = ko.observable<UserPermission>();
    selectedEntityOptions: Array<GenericEntityWithStatus> = Array()

    availableSecurityGroups: Array<SecurityGroup> = Array();
    securityGroupToAdd: ko.Observable<number> = ko.observable(0);
    securityGroupToRemove: ko.Observable<UserGroup> = ko.observable();

    expandedNodes: string[] = [];

    user: ko.Observable<User> = ko.observable(null);

    isReadOnly: ko.Observable<boolean> = ko.observable(true);
    showPermissions: ko.Observable<boolean> = ko.observable(true);
    allowEdit: ko.Observable<boolean> = ko.observable(true);

    isEditingGeneral: ko.Observable<boolean> = ko.observable(false);
    isEditingPermissions: ko.Observable<boolean> = ko.observable(false);
    
    bump: ko.Observable<boolean> = ko.observable<boolean>(false);

    dialogTitle: ko.Computed<string> = ko.computed((): string => {
        return this.user()
            ? `${this.user().userName()}`
            : 'User Dialog - Not Initialized';
    })

    isEditing: ko.Computed<boolean> = ko.computed((): boolean => {
        return !this.isReadOnly();
    })

    canEditJobTitle: ko.Computed<boolean> = ko.computed((): boolean => {
        if (this.user() != null && this.user().selectedContractJobTitleAssociationType() == EntityType.Facility) {
            return this.isEditingGeneral();
        }
        else {
            return false;
        }
    })

    showEditButton: ko.Computed<boolean> = ko.computed((): boolean => {
        return (this.isReadOnly() && this.allowEdit());
    })

    displayDescription: ko.Computed<string> = ko.computed<string>(() => {
        if (this.selectedPermission() != null) {
            return this.selectedPermission().description();
        }
        else {
            return 'select a permission';
        }
    })

    //these are for permissions like alarm that have elements with checkboxes
    displayEntityOptions: ko.PureComputed = ko.pureComputed(() => {
        var bump = this.bump();

        if (this.selectedPermissionId() > 0
                && this.selectedPermission() != null
                && this.selectedPermission().entityOptions != null) {

            return this.selectedPermission().entityOptions();
        }
        else {
            var arr = [
                new GenericEntityWithStatus(-99, '', false)
            ]
            return arr;
        }

    })

    selectedSecurityGroupPermissions: ko.PureComputed = ko.pureComputed(() => {
        let matchingPermissions = null;

        for (let i = 0; i < this.availableSecurityGroups.length; i++) {
            if (this.availableSecurityGroups[i].securityGroupId() == this.securityGroupToAdd()) {
                matchingPermissions = this.availableSecurityGroups[i].permissions();
            }
        }

        return matchingPermissions;
    })

    securityGroupToRemoveDescription: ko.Computed<string> = ko.computed((): string => {
        var bump = this.bump();

        if (this.securityGroupToRemove() != null) {
            return this.securityGroupToRemove().description;
        }
        else {
            return "Are you sure?";
        }

    })

    securityGroupToRemovePermissions: ko.PureComputed = ko.pureComputed(() => {
        
        let matchingPermissions = null;

        if (this.securityGroupToRemove() != null) {
            for (let i = 0; i < this.availableSecurityGroups.length; i++) {
                if (this.availableSecurityGroups[i].securityGroupId() == this.securityGroupToRemove().securityGroupId) {
                    matchingPermissions = this.availableSecurityGroups[i].permissions();
                }
            }
        }

        return matchingPermissions;
    })

    constructor(options) {
        if (options.isReadOnly !== undefined)  this.isReadOnly(options.isReadOnly);
        if (options.showPermissions !== undefined) this.showPermissions(options.showPermissions);
        
    }

    editGeneral = () => {
        this.isEditingGeneral(true);
    }

    saveGeneral = () => {
        this.isEditingGeneral(false);
        this._saveGeneral();
    }

    private _saveGeneral = () => {
        if (!this.hasUserErrors()) {

            $.ajax({
                url: '/api/user',
                method: this.user().userId() === 0 ? 'POST' : 'PUT',
                data: this._packageData(),
                cache: false,
                contentType: false,
                processData: false,
            })
                .done(results => {

                    let isNewUser = false;
                    if (this.user().userId() == 0) {
                        isNewUser = true;
                    }

                    this.user(new User(results, this));

                    if (isNewUser) {
                        //this._getAvailableSecurityGroups()
                        //    .then(() => this._getPermissions2())

                        this._getPermissions();
                    }

                    this._onSave(this.user().userId(), results);
                })
                .fail((request, textStatus, error) => {
                    
                    //
                    //  Since there was an error, put the general section back in edit mode
                    //
                    this.isEditingGeneral(true);

                    common.toast("error", error, 'Edit failure.');
                });
        }
    }

    private _onSave = (userId: number, userDetails) => {
        if (this.onSave) {
            this.onSave(userId, userDetails);
        }
    }

    private _packageData = (): FormData => {
        let formData = <FormData>this.user().toFormData();
 
        return formData;
    }

    cancelGeneral = () => {
        this._getUser(this.user().userId())
        this.isEditingGeneral(false);
    }

    edit = async (userId?: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            this._getUser(userId)
                .then(() => this._getPermissionNodeSettings())
                .then(() => this._getAvailableSecurityGroups())
                .then(() => this._getPermissions())
                .then(() => {
                    this._addValidators();
                    this.showDialog();
                    resolve()
                })
        })
    }

    private _getUser = (userId?: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (userId) {
                User.getById(userId)
                    .then(user => {
                        this.user(user)
                        resolve();
                    }, rejectReason => reject(rejectReason));
            } else {
                this.user(new User());
                resolve();
            }
        })
    }

    private _addValidators = () => {
        if (!this.user().hasValidators()) {
            this.user().firstName = Validation.addLength(this.user().firstName, { min: 2, max: 50, fieldName: 'First name', isRequired: false });
            this.user().lastName = Validation.addLength(this.user().lastName, { min: 2, max: 100, fieldName: 'Last name', isRequired: false });
            this.user().emailAddress = Validation.addRegEx(this.user().emailAddress, { regEx: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/), fieldName: 'Email Address', overrideMessage: 'Email Address is invalid', isRequired: true });
            this.user().userName = Validation.addLength(this.user().userName, { min: 4, max: 100, fieldName: 'UserName', isRequired: true });
            this.user().phoneNumber = Validation.addLength(this.user().phoneNumber, { min: 10, max: 12, fieldName: 'Phone Number', isRequired: false });

            this.user().hasValidators(true);
        }
    }

    hasUserErrors: ko.PureComputed<boolean> = ko.pureComputed((): boolean => {
        if (this.user && this.user().hasValidators()) {
            return (this.user().firstName as any).hasError()
                || (this.user().lastName as any).hasError()
                || (this.user().emailAddress as any).hasError()
                || (this.user().userName as any).hasError()
                || (this.user().phoneNumber as any).hasError()
        }
        else {
            return false;
        }
    })

    _getPermissionNodeSettings = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/user/setting/User-PermissionNodes`,
                cache: false
            })
                .done(data => {
                    if (data != "") {
                        this.expandedNodes = data.split('|');
                    }
                    else {
                        this.expandedNodes = [];
                    }
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error('Users::getPermissionNodeSettings', request, textStatus, error);
                    reject();
                })
        })
    };

    _getAvailableSecurityGroups = async (): Promise<void> => {
       
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/user/${this.user().userId()}/allSecurityGroups`,
                cache: false
            })
                .done(data => {
                    this.setAvailableSecurityGroups(data);
                    
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error('Users::getAvailableSecurityGroups', request, textStatus, error);
                    reject();
                })
        })
    };

    private setAvailableSecurityGroups = (data) => {
        const configurationMap = {
            key: (x) => ko.utils.unwrapObservable(x.securityGroupId),
            create: (x) => new SecurityGroup(x.data)
        };

        this.availableSecurityGroups = ko.utils.unwrapObservable( mapping.fromJS(data, configurationMap) );
    };


    _getPermissions = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.user().userId() > 0) {
                $.get({
                    url: `/api/user/${this.user().userId()}/permission`,
                    cache: false
                })
                    .done(data => {
                        this.setUserPermissions(data);
                        resolve();
                    })
                    .fail((request, textStatus, error) => {
                        console.error('Users::getPermissions', request, textStatus, error);
                        reject();
                    })
            } else {
                resolve();
            }
        })
    };

    private setUserPermissions = (data) => {
        const configurationMap = {
            key: (x) => ko.utils.unwrapObservable(x.permissionId),
            create: (x) => new UserPermission(x.data, this.user().userId(), this)
        };

        this.permissions = mapping.fromJS(data, configurationMap);

        this.buildPermissionTree();
    };

    buildPermissionTree = () => {

        let treeNodes: BSTreeViewNode[] = [];

        this.permissions().forEach(p => {
            treeNodes.push(this.permissionToTreeNode(p));
        });

        this.permissionTreeNodes = treeNodes;

        if (this.tree != null) {

            this.tree.remove();
        }

        if (this._dialogInitialized) {
            let treeElement = document.getElementById("treeview");

            this.tree = new BSTreeView(treeElement,
                //The options to apply to our treeView
                {
                    data: this.permissionTreeNodes,
                },
                //Themes to use for the BSTreeview. We use Bootstrap5 and FontAwesome5
                [BS5Theme, FAIconTheme]
            );


            if (!this._dialogTreeEventsInitialized) {


                treeElement.addEventListener(EVENT_NODE_SELECTED, (event: any) => {
                    let pid = event.detail.node.dataAttr.securableId;

                    this.selectedPermissionId(pid);
                    this._setPermissionDetails(pid);
                    this.bump(!this.bump());
                });

                treeElement.addEventListener(EVENT_NODE_EXPANDED, (event: any) => {
                    let pid = event.detail.node.dataAttr.securableId;

                    if (this.expandedNodes.indexOf(pid) == -1) {
                        this.expandedNodes.push(pid);

                        this.saveSetting("User-PermissionNodes", this.expandedNodes.join('|'));
                    }


                });

                treeElement.addEventListener(EVENT_NODE_COLLAPSED, (event: any) => {
                    let pid = event.detail.node.dataAttr.securableId;

                    if (this.expandedNodes.indexOf(pid) > -1) {
                        this.expandedNodes.splice(this.expandedNodes.indexOf(pid), 1);

                        this.saveSetting("User-PermissionNodes", this.expandedNodes.join('|'));
                    }

                });

                this._dialogTreeEventsInitialized = true;
            }

        }

    }

    private permissionToTreeNode(permission: UserPermission): BSTreeViewNode  {

        let treeNode = {} as BSTreeViewNode;
        treeNode.text = permission.description();

        if (this.expandedNodes.length > 0) {

            this.expandedNodes.forEach(expandedId => {
                if (permission.securableId() == Number(expandedId)) {
                    let nodeState = new BSTreeViewNodeState();
                    nodeState.expanded = true;
                    treeNode.state = nodeState;
                }
            });

        }


        // treeNode.[color] changes don't seem to apply, but using css works fine'
        if (permission.isAllowed() == 1) {
            treeNode.icon = "fas fa-check-circle";
            treeNode.class = "tree-icon-green";
        }
        else if (permission.isAllowed() == 0) {
            treeNode.icon = "fas fa-do-not-enter";
            treeNode.class = "tree-icon-red";
        }
        else if (permission.securableType() != 0) {             //don't put icons on grouping nodes
            if (permission.defaultIsAllowed() == 1) {
                treeNode.icon = "fal fa-check-circle";
                treeNode.class = "tree-icon-green";
            }
            else if (permission.defaultIsAllowed() == 0) {
                treeNode.icon = "fal fa-do-not-enter";
                treeNode.class = "tree-icon-red";
            }
        }

        treeNode.dataAttr = { "securableId": permission.securableId().toString() };

        if (permission.childPermissions != null && permission.childPermissions().length > 0) {
            treeNode.nodes = []; 

            permission.childPermissions().forEach(childPermission => {
                treeNode.nodes.push(this.permissionToTreeNode(childPermission));
            });
        }

        return treeNode;
    
    }

    _setPermissionDetails = (id: number) => {

        let selectedPermission;

        for (let a of this.permissions()) {
            selectedPermission = this.findPermissionById(id, a)
            if (selectedPermission != null) {

                this.selectedPermissionChoice(selectedPermission.isAllowed());
                this.selectedPermission(selectedPermission);

                break;
            }
        }
       
    }

    private findPermissionById(id: number, permission: UserPermission): UserPermission {

        if (permission.securableId() == id) {
            return permission;
        }

        let matchingChildPermission =  null;

        if (permission.childPermissions != null && permission.childPermissions().length > 0) {

            for (let a of permission.childPermissions()) {
                matchingChildPermission = this.findPermissionById(id, a);

                if (matchingChildPermission != null) {
                    return matchingChildPermission;
                }
            }
        }

        return null;
    }


    showDialog = () => {
        if ($('#userEditorDiv').length === 0) {
            this._getDialog()
                .then(() => $('#userEditorDiv').ready(this._showDialog));
        }
        else {
            this._showDialog();

        }
    }

    private _getDialog = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.get('/settings/users/fullscreeneditor')
                .done((results) => {
                    const div: HTMLDivElement = document.createElement('div');
                    div.id = 'userEditorDiv';
                    div.className = 'editor'
                    div.style.cssText = 'position: absolute; left: 0; top: 0; right: 0; bottom: 0; z-index: 10; display: none';
                    $('.body-content')[0].appendChild(div);
                    $('#userEditorDiv').html(results);
                    resolve();
                })
                .fail((a, b, c) => {
                    console.error("userEditorDiv::_getDialog", a, b, c);
                    reject();
                });
        });
    }

    getPhoto = () => {
        $('#userPhotoFile').trigger('click');
    }

    private _photoChanged = () => {
        const imgReader = new FileReader();

        imgReader.onload = (image) => {
            $('#userPhoto').attr('src', image.target.result as string);
        };

        imgReader.readAsDataURL(($('#userPhotoFile')[0] as any).files[0]);
    }

    private _dialogInitialized = false;
    private _dialogTreeEventsInitialized = false;
    private _showDialog = () => {
        if (!this._dialogInitialized) {
            $(':input').attr('data-lpignore', 'true');

            this._setupKeyBindings();


            window.eval(`$(document).popover({               
                html: true,
                container: 'body',
                boundary: 'window',
                placement: 'top',
                selector: '.validationTip',
                trigger: 'hover focus',
                template: '<div class="popover" role="tooltip"><div class="popover-body validation"></div></div>'
            })`);


        } else {
            this.selectedPermission(null);

            ko.cleanNode($('#userEditorDiv')[0]);
        }

        this.isEditingGeneral((this.user().userId() ?? 0) == 0);

        ko.applyBindingsToDescendants(this, $('#userEditorDiv')[0]);

        $('#userEditorDiv').addClass('show');




        //Choose the element on which you want to create the treeview
        let treeElement = document.getElementById("treeview");

        treeElement.innerHTML = "";


        if (this.user().userId() > 0) {

            this.tree = new BSTreeView(treeElement,
                //The options to apply to our treeView
                {
                    data: this.permissionTreeNodes,
                    //You can either use the callback provided in the options element...
                    //onNodeSelected: (event:any ) => {
                    //    const node = event.detail.node;
                    //    alert("You selected: " + node.text);
                    //}

                },
                //Themes to use for the BSTreeview. We use Bootstrap5 and FontAwesome5
                [BS5Theme, FAIconTheme]
            );


            if (!this._dialogTreeEventsInitialized) {
                treeElement.addEventListener(EVENT_NODE_SELECTED, (event: any) => {
                    let pid = event.detail.node.dataAttr.securableId;

                    this.selectedPermissionId(pid);
                    this._setPermissionDetails(pid);
                    this.bump(!this.bump());
                });

                treeElement.addEventListener(EVENT_NODE_EXPANDED, (event: any) => {
                    let pid = event.detail.node.dataAttr.securableId;

                    if (this.expandedNodes.indexOf(pid) == -1) {
                        this.expandedNodes.push(pid);

                        this.saveSetting("User-PermissionNodes", this.expandedNodes.join('|'));
                    }


                });

                treeElement.addEventListener(EVENT_NODE_COLLAPSED, (event: any) => {
                    let pid = event.detail.node.dataAttr.securableId;

                    if (this.expandedNodes.indexOf(pid) > -1) {
                        this.expandedNodes.splice(this.expandedNodes.indexOf(pid), 1);

                        this.saveSetting("User-PermissionNodes", this.expandedNodes.join('|'));
                    }

                });

                this._dialogTreeEventsInitialized = true;
            }
        }


        this._dialogInitialized = true;

        this.selectedPermissionId(0);


    }

    saveSetting = async (settingKey: string, newValue) => {
        const setting = `/api/user/setting/${settingKey}`
        $.post(setting, { settingValue: newValue });
    }

    showResetPasswordModal = (event) => {

        window.eval('$("#resetPasswordModal").modal("show")');
    }

    resetUserPassword = (): Promise<boolean> => {

        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/user/${this.user().userId()}/passwordReset`,
                method: 'PUT',
            })
                .done(data => {

                    window.eval('$("#resetPasswordModal").modal("hide")');
                    common.toast('success', `New Password Sent.`, 'Notification');
                    resolve(true);
                })
                .fail((request, textStatus, error) => {
                    window.eval('$("#resetPasswordModal").modal("hide")');
                    console.error('Users::passwordReset', request, textStatus, error);
                    reject(false);
                })
        })
    }

    showDeactivateModal = (event) => {

        window.eval('$("#deactivateUserModal").modal("show")');
    }

    deactivateUser = (): Promise<boolean> => {

        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/user/${this.user().userId()}/deactivate`,
                method: 'PUT',
            })
                .done(data => {

                    window.eval('$("#deactivateUserModal").modal("hide")');
                    this._onSave(this.user().userId(), this.user());
                    common.toast('success', `User Deactivated.`, 'Notification');
                    resolve(true);
                })
                .fail((request, textStatus, error) => {
                    window.eval('$("#deactivateUserModal").modal("hide")');
                    console.error('Users::UserDeactivation', request, textStatus, error);
                    reject(false);
                })
        })
    }

    showAddGroupModal = (event) => {
        window.eval('$("#addGroupModal").modal("show")');
    }

    addPermissionGroup = (): Promise<boolean> => {

        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/user/${this.user().userId()}/securityGroup`,
                method: 'POST',
                data: {
                    securityGroupId: this.securityGroupToAdd(),
                },
                cache: false,
            })
                .done(data => {

                    window.eval('$("#addGroupModal").modal("hide")');

                    this.user().getGroups()
                        .then(() => this._getPermissions())
                        .then(() => {
                            this.buildPermissionTree();
                            common.toast('success', `Group Added.`, 'Permissions Changed');
                            resolve(true);

                        });
                })
                .fail((request, textStatus, error) => {
                    window.eval('$("#addGroupModal").modal("hide")');
                    console.error('Users::addPermissionGroup', request, textStatus, error);
                    reject(false);
                })
        })
    }

    showRemoveGroupModal = (data: UserGroup, event) => {
        
        this.securityGroupToRemove(data);

        this.bump(!this.bump());

        window.eval('$("#removeGroupModal").modal("show")');
    }

    removePermissionGroup = (): Promise<boolean> => {

        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/user/${this.user().userId()}/securityGroup`,
                method: 'DELETE',
                data: {
                    securityGroupId: this.securityGroupToRemove().securityGroupId,
                },
            })
                .done(data => {
                    window.eval('$("#removeGroupModal").modal("hide")');

                    this.user().getGroups()
                        .then(() => this._getPermissions())
                        .then(() => {
                            this.buildPermissionTree();
                            common.toast('success', `Group Removed.`, 'Permissions Changed');
                            resolve(true);

                        });
                })
                .fail((request, textStatus, error) => {
                    window.eval('$("#removeGroupModal").modal("hide")');
                    console.error('Users::UserDeactivation', request, textStatus, error);
                    reject(false);
                })
        })
    }

    hideDialog = () => this._hideDialog();

    private _hideDialog = () => {
        //this._onBeforeHide();
        $('#userEditorDiv').removeClass('show');
        //this._onHidden();
    }

    private _setupKeyBindings = () => {
        $(document).keydown((e: any) => {
            if ($('.dropdown.show').length === 0) {
                e = e || window.event;

                switch (e.keyCode) {
                    case 27: //ESC
                        this.hideDialog();
                        e.preventDefault();
                        break;
                }
            }
        });
    }

}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Users ??= () => { /* */ };
globalThis.DIG.Users.UserDialog = UserDialogModel