﻿
import { CommonViewModel } from "../Common"
import ko = require("knockout");

const common: CommonViewModel = globalThis.DIG.Common

export class MaintenanceViewModel {

    userId: ko.Observable<number> = ko.observable(0);
    //facilityId: ko.Observable<number> = ko.observable(0);

    constructor() {

        ko.applyBindings(this);
    }

    refreshAllUsers = (): Promise<boolean> => {

        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/maintenance/refreshAllUsers`,
                method: 'POST',
                cache: false,
            })
                .done(data => {

                    common.toast('success', `All Users Reloaded`, 'Done');
                    resolve(true);

                })
                .fail((request, textStatus, error) => {
                    console.error('Users::refreshAllUsers', request, textStatus, error);
                    reject(false);
                })
        })
    }

    refreshSecurityGroups = (): Promise<boolean> => {

        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/maintenance/refreshSecurityGroups`,
                method: 'POST',
                cache: false,
            })
                .done(data => {

                    common.toast('success', `All Security Groups Reloaded`, 'Done');
                    resolve(true);

                })
                .fail((request, textStatus, error) => {
                    console.error('Users::refreshSecurityGroups', request, textStatus, error);
                    reject(false);
                })
        })
    }

    refreshContractAndFacilities = (): Promise<boolean> => {

        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/maintenance/refreshContractAndFacilities`,
                method: 'POST',
                cache: false,
            })
                .done(data => {

                    common.toast('success', `All Contracts and Facilities Reloaded`, 'Done');
                    resolve(true);

                })
                .fail((request, textStatus, error) => {
                    console.error('Users::refreshContractAndFacilities', request, textStatus, error);
                    reject(false);
                })
        })
    }
    
    refreshUser = (): Promise<boolean> => {

        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/maintenance/${this.userId()}/refreshUser`,
                method: 'POST',
                cache: false,
            })
                .done(data => {
    
                common.toast('success', `User Reloaded`, 'Done');
                resolve(true);

                })
                .fail((request, textStatus, error) => {
                    console.error('Users::refreshUser', request, textStatus, error);
                    reject(false);
                })
        })
    }

    refreshBuildings = (): Promise<boolean> => {

        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/maintenance/refreshBuildings`,
                method: 'POST',
                cache: false,
            })
                .done(data => {

                    common.toast('success', `Facility Buildings have been reloaded`, 'Done');
                    resolve(true);

                })
                .fail((request, textStatus, error) => {
                    console.error('Users::refreshBuildings', request, textStatus, error);
                    reject(false);
                })
        })
    }

    refreshZoneGroups = (): Promise<boolean> => {

        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/maintenance/refreshZoneGroups`,
                method: 'POST',
                cache: false,
            })
                .done(data => {

                    common.toast('success', `Zone Groups have been reloaded`, 'Done');
                    resolve(true);

                })
                .fail((request, textStatus, error) => {
                    console.error('Users::refreshZoneGroups', request, textStatus, error);
                    reject(false);
                })
        })
    }

    refreshInmates = (): Promise<boolean> => {

        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/maintenance/refreshInmates`,
                method: 'POST',
                cache: false,
            })
                .done(data => {

                    common.toast('success', `Inmates have been reloaded`, 'Done');
                    resolve(true);

                })
                .fail((request, textStatus, error) => {
                    console.error('Users::refreshInmates', request, textStatus, error);
                    reject(false);
                })
        })
    }

    refreshAlarms = (): Promise<boolean> => {

        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/maintenance/refreshAlarms`,
                method: 'POST',
                cache: false,
            })
                .done(data => {

                    common.toast('success', `Alarms have been Reloaded`, 'Done');
                    resolve(true);

                })
                .fail((request, textStatus, error) => {
                    console.error('Users::refreshAlarms', request, textStatus, error);
                    reject(false);
                })
        })
    }

    refreshZones = (): Promise<boolean> => {

        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/maintenance/refreshZones`,
                method: 'POST',
                cache: false,
            })
                .done(data => {

                    common.toast('success', `Zones And Location have been Reloaded`, 'Done');
                    resolve(true);

                })
                .fail((request, textStatus, error) => {
                    console.error('Users::refreshZones', request, textStatus, error);
                    reject(false);
                })
        })
    }

    
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { };
}

if (globalThis.DIG.Maintenance === undefined) {
    globalThis.DIG.Maintenance = () => { };
}

globalThis.DIG.Maintenance.ViewModel = MaintenanceViewModel