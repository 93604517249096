﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import Common = require("../Common")

const common: Common.CommonViewModel = globalThis.DIG.Common;

export class PlacementDevice {
    view: any // view should be ZoneDialog
    placementDeviceId: ko.Observable<number> = ko.observable(0)
    placementId: ko.Observable<number> = ko.observable(0)
    deviceId: ko.Observable<number> = ko.observable(0)
    assignedTimestamp: moment.Moment = ''.toMoment()
    removedTimestamp: moment.Moment = ''.toMoment()
    comment: ko.Observable<string> = ko.observable('')
    isMuted: ko.Observable<boolean> = ko.observable<boolean>(false)
    hasWarning: ko.Observable<boolean> = ko.observable<boolean>(false)
    firmwareVersion: ko.Observable<string> = ko.observable('')
    shouldSendConfig: ko.Observable<boolean> = ko.observable<boolean>(false)
    originalComment: string = ''
    originalIsMuted: boolean = false
    isSelected: ko.Observable<boolean> = ko.observable<boolean>(false)

    constructor(data?: object, view?: object) {
        this.view = view; // view should be ZoneDialog

        if (data) {
            mapping.fromJS(data, {}, this);
        }

        this.originalComment = this.comment();
        this.originalIsMuted = this.isMuted();

        this.isMuted.subscribe(newValue => {
            this.toggleIsMuted();
        });
    }

    hasCommentChanged: ko.PureComputed<boolean> = ko.pureComputed<boolean>(() => {
        return this.comment() != this.originalComment;
    })

    displayDeviceIdWithVersion: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        let displayStr = this.deviceId().toString();

        if (this.firmwareVersion() != null) {
            displayStr += ` (${this.firmwareVersion()})`;
        }

        return displayStr;
    })

    protected savePlacementDeviceChanges(): Promise<object> {
        return new Promise((resolve, reject) => {

            $.ajax({
                url: '/api/facility/placement/device', 
                method: 'PUT',
                data: {
                    placementDeviceId: this.placementDeviceId(),
                    //isMuted: this.isMuted(),
                    comment: this.comment()
                },
                cache: false,
                //contentType: false,
                //processData: false,
            })
                .done(results => {
                    if (results) {
                        this.originalComment = this.comment();
                        this.comment.valueHasMutated();
                        this.originalIsMuted = this.isMuted();
                        this.isMuted.valueHasMutated();
                    }
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("Locator::savePlacementDeviceChanges()", request, textStatus, error);
                    reject();
                })
        });

    }

    public removePlacementDevice(): Promise<object> {
        return new Promise((resolve, reject) => {

            $.ajax({
                url: '/api/facility/placement/device',
                method: 'DELETE',
                data: {
                    placementDeviceId: this.placementDeviceId(),
                    deviceId: this.deviceId()
                },
                cache: false,
            })
                .done(results => {
                    // view should be ZoneDialog
                    if (this.view) {
                        this.view.placementsChanged(this.placementId());
                    }
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("Locator::removePlacementDevice()", request, textStatus, error);
                    reject();
                })
        });
    }

    sendCommand = (action: number): Promise<void> => {
        return new Promise((resolve, reject) => {

            $.ajax({
                url: `/api/device/${this.deviceId()}/action/${action}`,
                method: 'GET',
                cache: false,
            })
                .done(results => {
                    // view should be ZoneDialog
                    //if (this.view) {
                    //    this.view.locatorsChanged(this.facilityBeaconGroupId());
                    //}
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error("Locator::sendCommand()", request, textStatus, error);
                    reject();
                })
        });
    }

    sendDFU = (advertiseSeconds: number): Promise<void> => {
        return new Promise((resolve, reject) => {

            $.ajax({
                url: `/api/device/${this.deviceId()}/dfu/${advertiseSeconds}`,
                method: 'GET',
                cache: false,
            })
                .done(results => {
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error("Locator::sendDFU()", request, textStatus, error);
                    reject();
                })
        });
    }

    toggleSendConfig = (shouldSendConfig: boolean): Promise<boolean> => {
        return new Promise((resolve, reject) => {

            $.ajax({
                url: `/api/device/${this.deviceId()}/shouldSendConfig`,
                method: 'POST',
                data: {
                    shouldSendConfig: shouldSendConfig
                },
                cache: false,
            })
                .done(results => {

                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("Device::toggleSendConfig()", request, textStatus, error);
                    reject();
                })


        });
    }

    public toggleIsMuted(): Promise<boolean> {
        return new Promise((resolve, reject) => {

            $.ajax({
                url: `/api/facility/placement/device/${this.placementDeviceId()}/isMuted`,
                method: 'POST',
                data: {
                    isMuted: this.isMuted()
                },
                cache: false,
            })
                .done(results => {

                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("Device::toggleIsMuted()", request, textStatus, error);
                    reject();
                })


        });
    }

}
