﻿import ko = require("knockout")
import mapping = require("knockout.mapping")
import moment = require("moment-timezone")
import Common = require("../Common")

import { TimeIntervalTypes } from "../TimeIntervalTypes"
import { LoadingIndicator } from "../LoadingIndicator"
import { Dialogs } from "../Dialogs"
import { GenericEntity, GenericEntityWithStatus } from "../GenericEntity"

const common: Common.CommonViewModel = globalThis.DIG.Common;

ko.components.register('PermissionWidget', {
    viewModel: function (params) {
        this.containerId = common.uniqueId();

        this.permission = params.permission;
        this.userId = params.userId


        this.permissionDescription = ko.computed(() => {
            if (
                this.permission() != null) {
                return this.permission().description();
            }
            else {
                return "";
            }
        });

        this.hasDetails = ko.computed(() => {
            //var bump = this.bump();
            if (this.permission() != null) {

                return true;

            }
            else {
                return false;
            }
        });

        this.hasYesNoDropdown = ko.computed(() => {
            if (this.permission() != null
                && (this.permission().securableType() > 0)) {

                return true;
            }
            else {

                return false;
            }
        });

        this.hasListDropdown = ko.computed(() => {

            //
            //  Show List options if permission is correct type, and isAllowed
            //
            if (this.permission() != null
                && (this.permission().securableType() == 1)
                && this.permission().isAllowed() == 1) {

                return true;
            }
            else {
                
                return false;
            }

        });

        
        this.hasEntityOptions = ko.computed(() => {
            
            //
            //  Show List options if permission is correct type, and isAllowed
            //
            if (this.permission() != null
                && this.permission().securableType() == 3
                && this.permission().isAllowed() == 1) {

                return true;

            }
            else {
                return false;
            }
        });

        this.isDefaults = ko.computed(() => {

            //
            //  If permission is inherited show the effective permission
            //
            if (this.permission() != null
                && this.permission().securableType() > 0
                && this.permission().isAllowed() == -1) {

                return true;

            }
            else {
                return false;
            }
        });

        this.displayEntityOptions = ko.pureComputed(() => {
            
            if (this.permission() != null
                && this.permission().entityOptions != null) {

                return this.permission().entityOptions();
            }
            else {
                var arr = [
                    new GenericEntityWithStatus(-99, '', false)
                ]
                return arr;
            }

        })

        this.displayDefaultAllowedList = ko.computed(() => {
            if (this.permission() != null) {

                if (this.permission().defaultIsAllowed() == 0) {
                    return "Permission Denied";
                }
                else {
                    return "Permission Allowed";
                }
            }
            else {
                return "";
            }
        });

        this.displayDefaultValueItem = ko.computed(() => {
            if (this.permission() != null
                && this.isDefaults()
                && (this.permission().securableType() == 1
                || this.permission().securableType() == 2)) {

                let winningDescription = "";

                this.permission().listOptions().forEach(item => {
                    if (item.id() == this.permission().defaultListOption()) {
                        winningDescription = "Access Level " + item.description();
                    }
                });

                return winningDescription;

            }
            else {
                return "";
            }
        });

        //this.displayDefaultEntityOptionsStr = ko.computed(() => {
        //    if (this.permission() != null
        //        && this.isDefaults()
        //        && this.permission().securableType() == 3) {

        //            let activeOptions: string[] = [];

        //            this.permission().defaultEntityOptions().forEach(item => {
                        
        //                if (item.isActive()) {
        //                    activeOptions.push(item.description());
        //                }

        //            });

        //            return activeOptions.join(", ");
        //    }
        //    else {
        //        return "";
        //    }
        //});

        this.displayDefaultEntityOptions = ko.computed(() => {
            if (this.permission() != null
                && this.isDefaults()
                && this.permission().securableType() == 3) {

                let activeOptions: string[] = [];

                this.permission().defaultEntityOptions().forEach(item => {

                    if (item.isActive()) {
                        activeOptions.push(item.description());
                    }

                });

                return activeOptions;
            }
            else {

                return [];
            }
        });

        this.displayDefaultSetBy = ko.computed(() => {
            if (this.permission() != null
                && this.isDefaults()) {

                if (this.permission().defaultSetBy != null && this.permission().defaultSetBy.id() > 0) {
                    return "Set by group: <b>" + this.permission().defaultSetBy.description() + "</b>";
                }
                else {
                    return "Inherit from <b>parent</b>";
                }

            }
            else {
                return "";
            }
        });

    },

    template:
        '<div class="permissionWidgetClass m-1" data-bind="attr: {id: containerId}, visible: hasDetails" style="border: 2px royalblue solid;padding: 2px; min-width:300px;"> \
            <div class="py-1 px-2 d-flex" style="background-color: royalblue; color: white; font-weight: bold;"> \
                <div data-bind="text: permissionDescription"></div> \
            </div> \
            <div class="d-flex">\
                <div class="pl-2 col-8"> \
                    <!-- ko if: hasYesNoDropdown --> \
                     <div> \
                        <div class="pt-2" ><input class="mr-1" type="radio" name="AllowedRadio" value="-1" data-bind="checked: permission().isAllowedString" />Inherited</div>\
                        <div class="py-2" style="min-height:140px;"> \
                            <div><input class="mr-1" name="AllowedRadio" type="radio" value="1" data-bind="checked: permission().isAllowedString" />Allowed</div>\
                            <div> \
                            <!-- ko if: hasListDropdown --> \
                                <div style="max-width: 150px;"><select class="form-control custom-select-sm" data-bind="options: permission().listOptions, optionsText: \'description\', optionsValue: \'id\', value: permission().selectedOption"></select></div>\
                            <!-- /ko --> \
                            </div> \
                            <div data-bind="visible: hasEntityOptions"> \
                                <div data-bind="foreach: displayEntityOptions"> \
                                    <div class="mx-2"> \
                                        <input type="checkbox" data-bind="checked: isActive"> \
                                        <span data-bind="text: description"></span> \
                                    </div> \
                                </div> \
                            </div>\
                        </div>\
                        <div class="pb-2"><input class="mr-1" name="AllowedRadio" type="radio" value="0" data-bind="checked: permission().isAllowedString" />Denied</div>\
                    </div> \
                    <!-- /ko --> \
                </div> \
                <div class="col-4" data-bind="visible: isDefaults"> \
                    <div data-bind="html: displayDefaultSetBy"></div> \
                    <div data-bind="text: displayDefaultAllowedList"></div> \
                    <div data-bind="text: displayDefaultValueItem"></div> \
                    <div> \
                    <ul data-bind="foreach: displayDefaultEntityOptions"> \
                        <li><span data-bind="text: $data"></span></li>\
                    </ul>\
                    </div> \
                </div> \
            <\div>\
        </div>'
})